import { Options, Vue } from 'vue-class-component';

@Options({
    name: "LostPasswordPage"
})
export default class LostPasswordPage extends Vue {

    emailSent: boolean = false;

    email: string = null;

    sendEmail() {
        // AuthApi
        //     .sendResetPassword({ email: this.email })
        //     .then(_ => this.emailSent = true);
    }
}