import { Options, Vue } from "vue-class-component";

@Options({
    name: "ResetPasswordPage",
    props: ['token']
})
export default class ResetPasswordPage extends Vue {
    newPassword: string = null;
    confirmPassword: string = null;
    passwordNotValid: boolean = false;

    isLoading: boolean = false;

    errorMessage: string = null;

    save() {
        if (!this.checkPassword()) {
            return;
        }

        this.isLoading = true;
        // AuthApi.setResetPassword(
        //     {
        //         password: this.newPassword,
        //         confirm_password: this.confirmPassword,
        //         token: this.token
        //     })
        //     .then(
        //         () => {
        //             this.$router.replace({ name: 'login' });
        //         },
        //         () => {
        //             this.errorMessage = "Errore: richiesta non valida";
        //         }
        //     )
        //     .finally(() => {
        //         this.isLoading = false;

        //     })
    }

    private checkPassword() {
        if (((!this.newPassword || this.newPassword === '')
            && (!this.confirmPassword || this.confirmPassword === ''))
            || (this.newPassword === this.confirmPassword)) {

            this.errorMessage = null;
            return true;
        }

        this.errorMessage = this.$t(`validation.pwd_and_confirm_match`);
        return false;
    }

}