import { defineComponent, reactive } from "vue";

import {
    Form as VForm,
    Field as VField,
    ErrorMessage as VError
} from 'vee-validate';

import { authService }      from "@services/auth.service";
import { AuthRoutesEnum }   from "../../router";
import { authStore }        from '../../store';
import { MainRoutesEnum } from "@/router/MainRoutesEnum";

import {
  VI18nError
} from "@components";

interface FormState {
    username: string;
    password: string;
    rememberMe: boolean;
}

export default defineComponent({
    components: {
        VForm,
        VField,
        VI18nError
    },

    setup() {
        const state = reactive<FormState>({
            username: null,
            password: null,
            rememberMe: false
        });

        return {
            ...state,
            forgotPasswordRoute: AuthRoutesEnum.FORGOTTEN_PWD,
            signUpRoute: AuthRoutesEnum.SIGN_UP
        }
    },

    methods: {
        async doLogin({ username: email, password }: FormState) {
            try {
                const response = await authService.login({ email, password })

                this.$localStorage.token = response.access_token;

                await authStore.actions.getMe();

                this.$router.replace({ name: MainRoutesEnum.HOME })
            } catch (error) {
                console.debug("error", error);
                this.$errorMessage(this.$t('login.error_message'));
            }
        },
    }
})